<template>
  <div class="bank-address-selector-container">
    <slot name="default" :value="modelValue" :valueText="valueText">
      <van-field
          v-model="valueText"
          type="textarea"
          autosize
          is-link
          readonly
          label="收款银行所在地区"
          placeholder="请选收款银行择所在地区"
          @click="show = true"
          :right-icon="value && value.length && clearable ? 'close': null"
          @click-right-icon.stop="modelValue = []"
      />
    </slot>
    <van-popup v-model="show" round position="bottom">
      <van-cascader
          v-model="cascaderValue"
          title="请选择收款银行所在地区"
          :options="options"
          :field-names="fieldNames"
          @close="show = false"
          @finish="onFinish"
      />
    </van-popup>
  </div>
</template>

<script>
import { getAdaPayAddress } from '@/api/user';

export default {
  name: 'BankAddressSelector',
  props: {
    value: {
      default: () => [],
      type: Array,
    },
    clearable: {
      default: () => false,
    },
  },
  data() {
    return {
      show: false,
      cascaderValue: '',
      fieldNames: {
        text: 'title',
        value: 'value',
        children: 'cities'
      },
      options: [],
    };
  },
  computed: {
    modelValue: {
      get() {
        this.$nextTick(this.fillEmptyText);
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    valueText() {
      let nameArr = [];
      this.modelValue.forEach(e => {
        let nameKey = this.fieldNames.text;
        let name = e[nameKey];
        name && nameArr.push(name);
      });
      return nameArr.join(' ');
    },
    nameMap() {
      let { options, treeToNameMap } = this;
      return treeToNameMap(options);
    },
  },
  created() {
    getAdaPayAddress().then(data => {
      this.options = Array.isArray(data) ? data : [];
      this.$nextTick(this.fillEmptyText);
    });
  },
  methods: {
    onFinish({ selectedOptions }) {
      this.modelValue = selectedOptions.map(e => ({ ...e }));
      this.show = false;
    },
    treeToNameMap(tree, map = {}) {
      let { value, text, children } = this.fieldNames;
      tree = Array.isArray(tree) ? tree : [];
      tree.forEach(e => {
        let key = e[value];
        let name = e[text];
        let child = e[children];
        map[key] = name;
        if (child && Array.isArray(child) && child.length)
          map = {
            ...map,
            ...this.treeToNameMap(child),
          };
      });
      return map;
    },
    fillEmptyText() {
      let { value, text } = this.fieldNames;
      let fillTextValue = this.value.map(e => {
        let val = e[value];
        let label = e[text];
        label = label || this.nameMap[val];
        return {
          [value]: val,
          [text]: label,
        };
      });
      this.$emit('input', fillTextValue);
    },
  },
};
</script>

<style lang="less">
.bank-address-selector-container {
  .van-tabs__line {
    background-color: @primary-color;
  }
}
</style>
