<template>
  <page-container class="user-info-container">
    <van-form
        @submit="onSubmit"
        label-width="8.5em"
        input-align="right"
    >
      <div class="user-info-card-item">
        <van-field
            class="user-info-avatar"
            readonly
            label="头像"
        >
          <template #input>
            <van-image class="user-info-avatar" :src="userInfo.avatar"/>
          </template>
        </van-field>
        <van-field
            :value="userInfo.nickname"
            readonly
            label="昵称"
        />
        <van-field
            :value="formatLevelName(userInfo.level)"
            readonly
            label="等级"
        />
        <van-field
            v-model.trim="form.name"
            :readonly="!canBeEditByStatus"
            :right-icon="renderRightIcon('name')"
            name="name"
            required
            label="真实姓名"
            placeholder="真实姓名"
            :rules="[{ required: true, message: '请输入真实姓名' }]"
        />
        <van-field
            v-model.trim="form.idNum"
            :type="inputType"
            :readonly="!canBeEditByStatus"
            :right-icon="renderRightIcon('idNum')"
            name="idNum"
            required
            label="身份证号"
            placeholder="身份证号"
            :rules="[{ required: true, message: '请输入身份证号' }]"
        >
        </van-field>
        <van-field
            v-model.trim="form.mobile"
            :type="inputType"
            :readonly="!canBeEditByStatus"
            :right-icon="renderRightIcon('mobile')"
            name="mobile"
            required
            label="手机号码"
            placeholder="手机号码"
            :rules="[{ required: true, message: '请输入手机号码' }, {pattern: /^[1][3,4,5,7,8,9][0-9]{9}$/, message: '请输入正确的手机号'}]"
        />
        <!-- <van-field
            readonly
            clickable
            right-icon="edit"
            name="bank"
            required
            label="收款银行"
            :value="form.bankName"
            placeholder="选择收款银行"
            :rules="[{ required: true, message: '请选择收款银行' }]"
            @click="showPicker = true"
        />
        <van-popup v-model="showPicker" round position="bottom">
          <van-picker
              show-toolbar
              :columns="bankList"
              value-key="label"
              @cancel="showPicker = false"
              @confirm="({ label, value }) => {
                this.form.bank = value;
                this.form.bankName = label;
                this.showPicker = false;
              }"
          />
        </van-popup> -->
        <!--        <van-field-->
        <!--            v-model.trim="form.subBank"-->
        <!--            name="subBank"-->
        <!--            label="开户支行"-->
        <!--            placeholder="开户支行"-->
        <!--            :rules="[{ required: true, message: '请输入开户支行' }]"-->
        <!--        />-->
        <!--        <bank-address-selector ref="bankAddressSelector" v-model="bankAddressInfo">-->
        <!--          <template #default="{ valueText }">-->
        <!--            <van-field-->
        <!--                :value="valueText"-->
        <!--                name="street"-->
        <!--                type="textarea"-->
        <!--                rows="1"-->
        <!--                :autosize="true"-->
        <!--                is-link-->
        <!--                readonly-->
        <!--                label="收款银行所在地区"-->
        <!--                placeholder="请选收款银行择所在地区"-->
        <!--                @click="$refs.bankAddressSelector.show = true"-->
        <!--                :rules="[{ required: true, message: '请选收款银行择所在地区' }]"-->
        <!--            />-->
        <!--          </template>-->
        <!--        </bank-address-selector>-->
        <!-- <van-field
            v-model.trim="form.bankAccountNo"
            :type="inputType"
            right-icon="edit"
            name="bankAccountNo"
            required
            label="银行卡号"
            placeholder="银行卡号"
            :rules="[{ required: true, message: '请输入银行卡号' }]"
        >
        </van-field> -->
      </div>

      <div class="submit-container">
        <p class="show-container">
          <span>是否展示身份证号、手机号码、银行卡号 </span>
          <van-switch
              size="1.8em"
              active-value="text"
              inactive-value="password"
              v-model="inputType"
          />
        </p>
        <van-button
            :loading="saving"
            round block type="primary"
            native-type="submit"
            :disabled="!!reSubmitCountDownTime"
        >
          <span v-show="!reSubmitCountDownTime">保存</span>
          <van-count-down
              v-if="reSubmitCountDownTime"
              style="display: inline-block"
              :time="reSubmitCountDownTime"
              format="ss"
              auto-start
              @finish="reSubmitCountDownTime = 0"
          >
            <template #default="timeData">
              请等待{{ timeData.seconds }}秒
            </template>
          </van-count-down>
        </van-button>
      </div>
    </van-form>
  </page-container>
</template>

<script>
import { getUserInfo, saveUser } from '@/api/user';
import { getBankList } from '@/api/user';
import BankAddressSelector from '@/components/BankAddressSelector';
import { Toast } from 'vant';

export default {
  name: 'userInfo',
  components: { BankAddressSelector },
  data() {
    return {
      userInfo: {},
      initInfo: null,
      bankList: [],
      showPicker: false,

      inputType: 'password',
      form: {
        name: '', // 真实姓名
        mobile: '', // 手机号码
        bank: '', // 开户行
        bankName: '',
        // subBank: '', // 开户支行
        bankProvince: '', // 银行所在省
        bankProvinceName: '',
        bankCity: '', // 银行所在市
        bankCityName: '',
        bankAccountNo: '', // 银行卡号
        idNum: '', // 身份证号
      },
      saving: false,
      reSubmitCountDownTime: 0,
    };
  },
  computed: {
    canBeEditByStatus() {
      return true;
      const { status } = this.userInfo;
      return status !== '02';
    },
    bankAddressInfo: {
      get() {
        let {
          bankProvince,
          bankProvinceName,
          bankCity,
          bankCityName,
        } = this.form;
        return [
          { value: bankProvince, title: bankProvinceName },
          { value: bankCity, title: bankCityName },
        ];
      },
      set(val) {
        let [
          { value: bankProvince = '', title: bankProvinceName = '' },
          { value: bankCity = '', title: bankCityName = '' },
        ] = Array.isArray(val) ? val : [];
        Object.assign(this.form, {
          bankProvince,
          bankProvinceName,
          bankCity,
          bankCityName,
        });
      },
    },
  },
  async created() {
    let countDown = window.localStorage.getItem('userInfoReSubmitCountDown') || 0;
    if (countDown && Date.now() < countDown) {
      this.reSubmitCountDownTime = +countDown - Date.now()
    }

    await getUserInfo().then(res => {
      this.userInfo = res || {};
      this.form = [
        {},
        'name',
        'mobile',
        'bank',
        'bankProvince',
        'bankProvinceName',
        'bankCity',
        'bankCityName',
        'bankAccountNo',
        'idNum'
      ].reduce((obj, key) => {
        obj[key] = this.userInfo[key] || '';
        return obj;
      });
      this.initInfo = { ...this.form };
    });
    getBankList().then(res => {
      this.bankList = res || [];
      let bankName = '';
      for (let bank of this.bankList) {
        if (bank.value === this.userInfo.bank) {
          bankName = bank.label;
          break;
        }
      }
      this.form.bankName = bankName;
    });
  },
  methods: {
    canBeEdit(key) {
      let { initInfo } = this;
      return initInfo && !initInfo[key];
    },
    renderRightIcon(key) {
      // return this.canBeEdit(key) ? 'edit' : '';
      return this.canBeEditByStatus ? 'edit' : '';
    },
    formatLevelName(level) {
      return {
        '1': '会员',
        '2': 'VIP',
        '3': '推广员',
        '4': '店铺',
      }[level];
    },
    onSubmit() {
      this.saving = true;
      saveUser({ ...this.form }).then(res => {
        if (res === true) {
          window.localStorage.setItem('userInfoReSubmitCountDown', Date.now() + 30 * 1000);
          this.reSubmitCountDownTime = 30 * 1000;
          Toast('个人信息保存成功');
          this.$router.back();
        }
        this.saving = false;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.user-info-container {
  .user-info-card-item {
    margin: 10px;
    border-radius: 15px;
    background-color: #fff;
    overflow: hidden;
  }

  .user-info-avatar {
    /deep/ .van-field__label {
      display: flex;
      align-items: center;
    }

    .van-image {
      width: 40px;
      height: 40px;
    }
  }

  .show-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 32px;
    text-align: right;
    font-size: 12px;
    color: #666;

    & > span {
      margin-right: 10px;
    }
  }

  .submit-container {
    padding: 10px 20px 20px 20px;
  }

}
</style>
